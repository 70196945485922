import React from 'react';

export function useApi<T, D extends Array<any>>(fn: (d?: D) => PromiseLike<T>, deps?: D): T | undefined {
  const [response, setResponse] = React.useState<T>();
  React.useEffect(() => {
    let good: true | undefined = true;
    fn(deps).then(data => good && setResponse(data));
    return () => (good = undefined);
  }, deps);
  return response;
}

interface UseApi<T> {
  data?: T;
  setData(data: T): void;
  refresh(): void;
}

export function useAPI<T, D extends Array<any>>(fn: (d?: D) => PromiseLike<T>, deps: D): UseApi<T> {
  const [data, setData] = React.useState<T>();
  const good = React.useRef<true | undefined>(true);
  const refresh = React.useCallback(() => {
    fn(deps).then(d => good.current && setData(d));
  }, deps);

  React.useEffect(refresh, deps);
  React.useEffect(() => () => (good.current = undefined), []);
  return { data, setData, refresh };
}
